import React from "react"
import { useContext } from "react";
import { DashboardContext } from "../context/DashboardContext";

//15.915
//Ring Component inspired by the post at: https://www.chilitime.design/2018/09/ActivityRings/

interface RingProperties{
    color: string;
    progress: number;
    strokeWidth: number;
    size: number;
    scale?: number;
    filter?: string;
}

const Ring: React.FC<RingProperties> = ({
    progress,
    strokeWidth,
    size, 
    color, 
    scale=1,
    filter
}) => {


    const {filters, setFilters} = useContext(DashboardContext);

    if(filter && filters.length>0 && !filters.includes(filter)){
        return null;
    }

    function onlyThisone(){
        return filter && filters.length===1 && filters.includes(filter)
    }

    function scale_(){
        if(onlyThisone()){
            return 1.4;
        }
        else{
            return scale
        }
    }

    function strokeWidth_(){
        if(onlyThisone()){
            return 3;
        }
        else{
            return strokeWidth
        }
    }

    return(
        <>

        <svg 
            onClick={()=>{
                if(filter){
                    if(filters.length>1){
                        setFilters([filter]);
                    }
                    else if(filters.includes(filter)){
                        setFilters([]);
                    }else{
                        setFilters([filter]);
                    }
                }
            }}
        style={{
            cursor: "pointer",
            transform: `scale(${scale_()}) rotate(-90deg)`
        }} className="ActivityRings" viewBox={`0 0 ${size} ${size}`}>
            <g className="ring">
                <circle className="background"
                    style={{stroke: "rgba("+color+",0.1)"}}
                    cx="50%" cy="50%"
                    strokeWidth={strokeWidth_()} 
                    r="15.915"/> 
                {progress>0
                &&
                <circle className="completed"
                style={{stroke: "rgba("+color+",1)"}}
                cx="50%" cy="50%"
                strokeWidth={strokeWidth_()} 
                r="15.915"
                strokeDasharray={`${progress}, 100`} />
                }
            </g>
        </svg>
    
        
        {onlyThisone() && <p className="big-number" style={{color:"rgba("+color+",1)"}}><b className="font-bold">{progress}</b>%</p>}
                
        </>
    );
}

export default Ring;