import React from "react"

import Ring from "./rint"
const exclamationicon = require('../img/exclamation-icon.png');

interface InsightsProps{
    title:string;
    insight: any;
    color: string;
}

const Insight: React.FC<InsightsProps> = ({insight, title, color}) => {
    if(!insight){
        return null;
    }

    return(
        <div className="w-full mt-4 bg-white p-6 rounded-xl fade-in" style={{maxWidth:424}}>
                <div className="flex flex-row justify-center">
                    
                    <div className="flex flex-row w-full items-center">
                        <div className="mr-1" style={{width:32}}>
                        <Ring 
                            color={color}
                            progress={insight.data.value}
                            strokeWidth={8}
                            size={45}
                            ></Ring>
                        </div>
                        <h3 className="text-purple font-bold text-lg mr-2" >{title}</h3>
                        <img src={exclamationicon} style={{width:24, height:"auto"}} alt="" />
                    </div>

                    <p className="text-purple font-bold text-xl">{insight.data.value}%</p>

                </div>
                <h3 className="text-gray font-bold text-2xl mt-2">{insight.data.title}</h3>
                <p className="text-gray font-medium text-xl">
                    {insight.data.subtitle}
                </p>
                <ul className="text-gray mt-3 ml-4">
                    
                    {insight.message_content.split(",").map((m:string, i:number) => {
                        return <li className="list-disc mb-2" key={i}>{m}</li>

                    })}
                </ul>
        </div>
    )   
}

export default Insight;