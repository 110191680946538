import { useState, useRef, useEffect, useContext } from "react";
import badge_icons from "../constants/badges_img";
import { DashboardContext } from "../context/DashboardContext";



interface BadgesProps {
    badges: any[];
}

const Badges: React.FC<BadgesProps> = ({badges}) => {


    const {fromDate, toDate} = useContext(DashboardContext);

   

    const [filteredBadges, setFilteredBadges] = useState<any[]>(badges);

    //slides code
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState<number | null>(null);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [prevTranslate, setPrevTranslate] = useState(0);
    const [slideWidth, setSlideWidth] = useState(0); // Store the width of a slide
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current slide index

    const slidesRef = useRef<HTMLDivElement | null>(null);
    const dragThreshold = 0.05; // 10% threshold for slide change

    useEffect(() => {
        if (slidesRef.current) {
            // Assuming all slides have the same width, get the width of one slide
            const slideElement = slidesRef.current.querySelector(".slide") as HTMLElement;
            setSlideWidth(slideElement.offsetWidth);
        }
    }, []);

    useEffect(() => {
        //filter badges based on the dates via the awarded_date property
        if(fromDate !== "" && toDate !== ""){
            const filteredBadges_:any[] = badges.filter((badge)=>{
                const awarded_date = new Date(badge.awarded_date);
                return awarded_date >= new Date(fromDate) && awarded_date <= new Date(toDate);});

            setFilteredBadges(filteredBadges_);
        }else{
            setFilteredBadges(badges);
        }
    }, [fromDate, toDate]);

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - slidesRef.current!.offsetLeft);
        setPrevTranslate(currentTranslate);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;
        const currentX = e.pageX - slidesRef.current!.offsetLeft;
        const deltaX = currentX - (startX as number);

        setCurrentTranslate(prevTranslate + deltaX);
    };

    const handleMouseUp = () => {
        if (!isDragging) return;
        setIsDragging(false);

        const dragDistance = currentTranslate - prevTranslate;

        if (dragDistance < -slideWidth * dragThreshold) {
            // Dragged left, move to the next slide
            setCurrentIndex((prev) => Math.min(prev + 1, filteredBadges.length - 1));
        } else if (dragDistance > slideWidth * dragThreshold) {
            // Dragged right, move to the previous slide
            setCurrentIndex((prev) => Math.max(prev - 1, 0));
        }

        // Snap to the nearest slide based on the currentIndex
        setCurrentTranslate(-currentIndex * slideWidth);
    };

    const NextSlide = ()=>{
        var i = currentIndex+1;
        if(i >= filteredBadges.length){
            i = 0;
        }
        setCurrentIndex(i);
        setCurrentTranslate(-currentIndex * slideWidth);
    }

    const PrevSlide = ()=>{
        var i = currentIndex-1;
        if(i < 0){
            i = filteredBadges.length-1;
        }
        setCurrentIndex(i);
        setCurrentTranslate(-currentIndex * slideWidth);
    }

    const GoToSlide = (index: number) => {
        setCurrentIndex(index);
        setCurrentTranslate(-index * slideWidth);
    };

    if(filteredBadges.length === 0){
        return null;
    }

    return (
        <div className="w-[524px] h-[322px] relative mb-32">
            <div className="spline-numbers w-full h-full absolute top-0 left-0 bg-repeat-x" style={{backgroundSize:680, backgroundPosition:currentTranslate*-0.2, transition:"all 0.3s ease-out"}}></div>
            <div className="spline-purple w-full h-full absolute top-0 left-0 bg-repeat-x" style={{backgroundSize:680, backgroundPosition:currentTranslate*0.2 , transition:"all 0.3s ease-out"}}></div>
            
            <div 
                className="slides-container"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
            <div
                ref={slidesRef}
                className="slides"
                style={{
                    width: `${filteredBadges.length * 100}%`, // Adjust width based on the number of slides
                    transform: `translateX(${currentTranslate}px)`,
                    cursor: isDragging ? "grabbing" : "grab",
                    transition: isDragging ? "none" : "transform 0.3s ease-out",
                }}
            >

                {filteredBadges.map((badge, index) => {
                    return(
                        <div className="slide" ref={slidesRef}>
                            <img className="w-[224px] mx-auto relative" src={ badge_icons[badge.badge_type] ? badge_icons[badge.badge_type]:badge_icons["engagement"] } alt="" />
                            <h4 className="text-center text-3xl font-bold mt-12">{badge.name.toUpperCase()}</h4>
                            <p className="text-center text-xl">{badge.description}</p>
                        </div>
                    )
                })}

            </div>
                <button onClick={NextSlide} className="h-full w-[30%] absolute left-0 top-0"></button>
                <button onClick={PrevSlide} className="h-full w-[30%] absolute right-0 top-0"></button>

                <div className="flex flex-row w-full text-center justify-center mt-[-5px]">
                    {filteredBadges.map((badge, index) => {
                        return(<div onClick={()=>{ GoToSlide(index); }} style={ currentIndex===index ? {background:"#A89ADF", border:"2px solid #A89ADF"}:{border:"2px solid #A89ADF"} } className="w-[14px] h-[14px] bg-gray-200 rounded-full cursor-pointer mr-2"></div>)
                    })}
                </div>
            </div>
            
        </div>
    );
}

export default Badges;