import React from 'react';

//boilerplate react component with typescript
const LandingPage: React.FC = () => {
    return (
        <div className='flex flex-col w-fit mx-auto mt-12 p-10 rounded-xl bg-white shadow-sm'>
            <h1 className='text-xl font-bold w-full'>Teamflo</h1>
            <p>
                A platform for teams to keep track of what is happening.
            </p>
        </div>
    );
}

export default LandingPage; // Path: src/routes/landing.tsx