import React from "react";

interface MiniLineChartProps {
  className?: string;
  values: number[];
  width?: number;
  height?: number;
  strokeColor?: string;
  strokeWidth?: number;
}

const MiniLineChart: React.FC<MiniLineChartProps> = ({
  values,
  width = 300,
  height = 150,
  strokeColor = "black",
  strokeWidth = 2,
  className
}) => {
  if (values.length === 0) {
    return <svg width={width} height={height} />;
  }

  const maxValue = Math.max(...values);
  const minValue = Math.min(...values);
  const pointSpacing = (width-20) / (values.length - 1);

  // Normalize the values to fit within the height of the SVG
  const getY = (value: number) =>
    (height-10) - ((value - minValue) / (maxValue - minValue)) * (height-10);

  const points = values.map((value, index) => ({
    x: index * pointSpacing,
    y: getY(value),
  }));

  // Function to generate the cubic Bézier curve command for smooth lines
  const generatePath = (points: { x: number; y: number }[]) => {
    let pathD = `M ${points[0].x},${points[0].y}`;

    for (let i = 0; i < points.length - 1; i++) {
      const p = points[i];
      const next = points[i + 1];
      const midX = (p.x + next.x) / 2;
      const midY = (p.y + next.y) / 2;

      pathD += ` Q ${p.x},${p.y} ${midX},${midY}`;
    }

    const lastPoint = points[points.length - 1];
    pathD += ` T ${lastPoint.x},${lastPoint.y}`;

    return pathD;
  };

  const pathData = generatePath(points);

  return (
    <svg width={width} height={height} className={className?className:""}>
        <path d={pathData} fill="none" stroke={"rgba("+strokeColor+",1)"} strokeWidth={strokeWidth} />
        {/* Add the circle at the last point */}
        <circle 
            cx={points[points.length - 1].x} 
            cy={points[points.length - 1].y} 
            r={7} 
            fill={"rgba("+strokeColor+",1)"}
            stroke="white" 
            strokeWidth={3} 
        />
    </svg>
  );
};

export default MiniLineChart;
