import React from "react";
import { useState, useEffect, createContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { sentiment_rgb, engagement_rgb, caring_rgb } from "../config";

import { DashboardContext } from "../context/DashboardContext";

import { TeamfloAPI } from "../manager/apimanager";

import Header from "../components/header";
import Ring from "../components/rint";
import Insight from "../components/insight";
import LineChart from "../components/lineChart";
import TimeFilter from "../components/timeFilter";
import BestMetrics from "../components/BestMetrics";
import Badges from "../components/Badges";

import InsightManager from "../manager/insightManager";
import { debug } from "console";


const DashboardHomePage: React.FC = () =>{
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();


    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('User');


    
    const [insights, setInsights] = useState<any[]>([]);//holds all insigths
    const [filteredInsights, setFilteredInsights] = useState<any[]>([]);//holds the filtered insights for the text widgets.
    const [lineChartData, setLineChartData] = useState<any>([]);//holds the insights for the line chart.

    const [sprints, setSprints] = useState<any[]>([]);
    const [badges, setBadges] = useState<any[]>([]);
    const [filters, setFilters] = useState<string[]>([]);

    const [fromDate, setFromDate] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");

    useEffect(()=>{
        if(searchParams.has("token")){
            const token = (searchParams.get("token") as string);
            GetData(token);
        }
        else{
            navigate("/");
        }
    }, []);

    useEffect(()=>{

        if(fromDate && toDate){
            const from = new Date(fromDate);
            const to = new Date(toDate);

            const from_linechard = new Date(from);
            const to_linechard = new Date(to);
            from_linechard.setDate(from_linechard.getDate() - 28);
            to_linechard.setDate(to_linechard.getDate() + 28);

            const filtered_:any[]= [];

            const filterdForChart:any[] = [];

            insights.forEach((insight:any) => {
                const start = new Date(insight.date_start);
                const end = new Date(insight.date_end);

                //hard filter is for the filtered insights for the text widgets.
                if(start >= from && end <= to){
                    filtered_.push(insight);
                }

                //for the linechart we want to show the previous and next four weeks around the filter date.
                if(start >= from_linechard && end <= to_linechard){
                    filterdForChart.push(insight);
                }
            });

            setFilteredInsights(filtered_);
            setLineChartData(filterdForChart);
            setFilters([]);
        }
        else{
            setFilteredInsights(insights);
            setLineChartData(insights);
            setFilters([]);
        }

    }, [fromDate, toDate]);

    const GetData = async (token:string) => {
        const data = await TeamfloAPI.getUser(token);

        if(data){
            setName(data.first_name);

            const newInsights:any[] = [];

            for(let i=0; i<data.projects.length; i++){
                const projectDetails = await TeamfloAPI.getProjectDetails(token, data.projects[i].id);
                projectDetails.insights.forEach((insight:any) => { 
                    newInsights.push(insight); 
                });

                setSprints(projectDetails.project.sprints);

                if(projectDetails.badges){
                    setBadges(projectDetails.badges);
                }
            }

            setInsights(newInsights);
            setFilteredInsights(newInsights);
            setLineChartData(newInsights);

            setLoading(false);
        }
        else{
            navigate("/");
        }
    }

    if(loading){
        return <div>Loading...</div>
    }

    return (
        <DashboardContext.Provider value={{
            filters, 
            setFilters,
            fromDate,
            toDate,
            setDates: (fromDate: string, toDate:string) => {setFromDate(fromDate); setToDate(toDate);}
        }}>
        <div className="flex flex-col mx-auto items-center" style={{maxWidth:524, paddingBottom:264}}>
            <Header></Header>
            <p className="text-center text-lg">Hello, {name}!</p>

            <Badges badges={badges}></Badges>

            <h2 className="text-center font-bold text-3xl">Here's your sprint recap</h2>

            <div className="RingContainer mt-8 mb-6 mx-auto" style={{width: 222}}>
                
                <Ring 
                    color= {sentiment_rgb}
                    progress={InsightManager.GetLatest(filteredInsights, "sentiment_individual")?.data?.value}
                    strokeWidth={3}
                    size={40}
                    scale={1.4}
                    filter={"sentiment"}
                ></Ring>
                
                <Ring 
                    color= {caring_rgb}
                    progress={InsightManager.GetLatest(filteredInsights, "caring_individual")?.data?.value}
                    strokeWidth={4.5}
                    size={40}
                    scale={1.05}
                    filter={"caring"}
                ></Ring>

                <Ring 
                    color= {engagement_rgb}
                    progress={InsightManager.GetLatest(filteredInsights, "engagement_individual")?.data?.value}
                    strokeWidth={7}
                    size={40}
                    scale={0.66}
                    filter={"engagement"}
                ></Ring>
                
            </div>

            {(filters.length === 0 || filters.includes("engagement")) && 
            <Insight 
            title={"Engagement"} 
            color={engagement_rgb}
            insight={InsightManager.GetLatest(filteredInsights, "engagement_individual")}/>
            }

            {(filters.length === 0 || filters.includes("caring")) && 
            <Insight 
                title={"Caring"} 
                color={caring_rgb}
                insight={InsightManager.GetLatest(filteredInsights, "caring_individual")}/>
            }

            {(filters.length === 0 || filters.includes("sentiment")) && 
            <Insight 
                title={"Sentiment"}
                color={sentiment_rgb}
                insight={InsightManager.GetLatest(filteredInsights, "sentiment_individual")}/>
            }

            <p className="text-purple text-left w-full text-xl mt-8 mb-8" style={{maxWidth:424}}>
                Sprint performance over time
            </p>

            <LineChart  insights={lineChartData} selectedInsight={InsightManager.GetLatest(filteredInsights, "engagement_individual")} />

            <BestMetrics insights={lineChartData} />

            <TimeFilter sprints={sprints}/>

        </div>
        </DashboardContext.Provider>
    );
}

export default DashboardHomePage; // Path: src/routes/dashboard.tsx