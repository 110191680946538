import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';


//import routes
import LandingPage from './routes/landing';
import DashboardHomePage from './routes/dashboard';


const mainRouter = createBrowserRouter([
  {
    path:"/",
    element:<LandingPage/>
  },
  {
    path:"/dashboard",
    element:<DashboardHomePage/>
  }
]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={mainRouter} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
