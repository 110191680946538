export const ENV = "dev";

let API_URL = "https://dev.api.teamflo.ai/api/v1/";

if(ENV==="local"){
    API_URL = "http://localhost:8000/api/v1/";
}

if(ENV==="prod"){

}


const sentiment_rgb = '48, 180, 188';
const caring_rgb = '135, 43, 147';
const engagement_rgb = '222, 122, 4'

export {API_URL, sentiment_rgb, caring_rgb, engagement_rgb};