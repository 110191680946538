import React from "react"

const headerBG = require("../img/header-bg.png");
const logo = require("../img/teamflo-icon-purple.png");

const Header: React.FC = () => {
    return(
    <div className="mx-auto mb-12" style={{maxWidth:524}}>
        <img src={headerBG} alt="decorative graphic" className="w-full"  />
        <div 
            className="mx-6 rounded-2xl relative flex flex-row justify-between items-center px-6" 
            style={{
                    height: 70,
                    marginTop:-100, 
                    backdropFilter:"blur(180px)", 
                    opacity:0.993
                }}
        >
            <img src={logo} alt="" style={{width:"auto", height:29}} />
            <div className="flex flex-row">
                <div className="dot" style={{background:"#872B93"}}></div>
                <div className="dot" style={{background:"#AE70B6"}}></div>
                <div className="dot" style={{background:"#C99FCE"}}></div>
            </div>
        </div>
    </div>)
}

export default Header;