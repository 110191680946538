import {  useState, useEffect, useContext } from "react";

import { DashboardContext } from "../context/DashboardContext";

import { engagement_rgb, sentiment_rgb, caring_rgb } from "../config";

import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, CoreChartOptions, elements } from "chart.js";

import InsightManager from "../manager/insightManager";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);


const engagement_icon = require('../img/engagement_icon.png');
const caring_icon = require('../img/caring_icon.png');
const sentiment_icon = require('../img/sentiment_icon.png');

const engagement_icon_w = require('../img/engagement_icon_white.png');
const caring_icon_w = require('../img/caring_icon_white.png');
const sentiment_icon_w = require('../img/sentiment_icon_white.png');


const options:any={
    scales:{
      y: {
        min:0,
        max:100, 
        innerHeight:500
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels:{
          useBorderRadius:true,
          boxWidth:10,
          boxHeight:10,
          borderRadius:5
        }
      },
    },
    elements: {
      line: {
        lineTension: 0.45,
        borderWidth:7
      }, 
      point:{
        radius:6,
        borderWidth:5
      }
  }
}

interface LineChartProps {
    insights: any;
    selectedInsight: any;
}

const MakeDataSet = (values:any, label:string, color:string):any => {
    return {
        label: label,
        data: values,
        fill: false,
        borderColor: color,
        tension: 0.1
    }
};

// Function to replace nulls with the previous valid value
const fillNulls = (array:any[]) => {
  for (let i = 1; i < array.length; i++) {
      if (array[i] === null) {
          array[i] = array[i - 1];
      }
  }
  for (let i = array.length - 2; i >= 0; i--) {
      if (array[i] === null) {
          array[i] = array[i + 1];
      }
  }
  return array;
};



const LineChart: React.FC<LineChartProps> = ({insights, selectedInsight}) => {

    const {filters, setFilters, fromDate, toDate} = useContext(DashboardContext);

  
    const [data, setData] = useState<any>(null);
    //for the chart to support multiple lines we need to filtrate data by different types of insights. 
    //For now we will only support one line.
    //var data = InsightManager.GetLabelsAndValues(insights, "engagement_individual");

    useEffect(()=>{
      SetupData();
    }, [filters]);
    

    function SetupData(){
        const engagement_metrics:any = InsightManager.GetLabelsAndValues(insights, "engagement_individual", 10) || [];
        const caring_metrics:any = InsightManager.GetLabelsAndValues(insights, "caring_individual", 10) || [];
        const sentiment_metrics:any = InsightManager.GetLabelsAndValues(insights, "sentiment_individual", 10) || [];
    
        //create the labels from the keys of the metrics, without repearing values and organizing them in order.
        //This will be the x axis of the chart.
        const d = []
        if(filters.length === 0){
          d.push(...Object.keys(engagement_metrics));
          d.push(...Object.keys(caring_metrics));
          d.push(...Object.keys(sentiment_metrics));
        }
        else{
          if(filters.includes("engagement")){ d.push(...Object.keys(engagement_metrics)); }
          if(filters.includes("caring")){ d.push(...Object.keys(caring_metrics));}
          if(filters.includes("sentiment")){ d.push(...Object.keys(sentiment_metrics));}
        }
    
        // Step 1: Create a labels array containing all unique keys
        const allKeys = new Set(d);
        const labels = Array.from(allKeys).sort();
    
        // Step 2: Create arrays for each object based on the labels
        const engagementData = labels.map(key => engagement_metrics[key] || null);
        const caringData = labels.map(key => caring_metrics[key] || null);
        const sentimentData = labels.map(key => sentiment_metrics[key] || null);
    
        fillNulls(engagementData);
        fillNulls(caringData);
        fillNulls(sentimentData);

        const datasets = [];
        if(filters.length === 0 || filters.includes("engagement")){
          datasets.push(MakeDataSet(engagementData, "Engagement",  `rgba(${engagement_rgb}, 1)`));
        }
        if(filters.length === 0 || filters.includes("caring")){
          datasets.push(MakeDataSet(caringData, "Caring",  `rgba(${caring_rgb}, 1)`));
        }
        if(filters.length === 0 || filters.includes("sentiment")){
          datasets.push(MakeDataSet(sentimentData, "Sentiment",  `rgba(${sentiment_rgb}, 1)`));
        }

        setData({
          labels: labels,
          datasets: datasets
        });
    }



    const ToggleFilter = (type:string) => {
      //add or remove the filter from the filter array
      if(filters.includes(type)){
        setFilters(filters.filter((f:string) => f !== type));
      }
      else{
        setFilters([...filters, type]);
      }
    }

    interface FilterButtonProps{
      icon: any, 
      icon_selected:any,
      filter:string,
      bg_color:string,
      border_color:string,
      label:string
    }

    function FilterButton({icon, filter, bg_color, border_color, icon_selected, label}:FilterButtonProps){

      const enabled =  filters.includes(filter);

      const style:any = {borderBottomWidth:12, borderColor:border_color}
      if(enabled){
        style.backgroundColor = bg_color;
      }

      return(
        <button  onClick={()=>{ ToggleFilter(filter) }} >
          <div className="bg-gray-100 mx-2 p-4 rounded-xl" style={style} >
            <img src={enabled?icon_selected:icon} alt="" style={{height:46, width:"auto" }}/>
          </div>
          <p className="font-medium text-sm text-gray-700 mt-2">{label}</p>
        </button>
      )
    }

    function GetPositionForMarker(){

      const insight_label = selectedInsight.date_end.slice(5);
      const increase = (424  / data.labels.length)+15;

      for (let i = 0; i < data.labels.length; i++) {
        if(data.labels[i] === insight_label){
          return (increase * i) + "px";
        }
      }

    }

    return(
        <div style={{width:424, height:324}}>
            <div className="flex flex-row items-center justify-center">
              <FilterButton 
                icon={engagement_icon} 
                icon_selected={engagement_icon_w} 
                bg_color={`rgba(${engagement_rgb}, 0.85)`}
                border_color={`rgba(${engagement_rgb}, 1)`}
                label="Engagement"
                filter="engagement" />
              <FilterButton 
                icon={caring_icon} 
                icon_selected={caring_icon_w}
                bg_color={`rgba(${caring_rgb}, 0.85)`}
                border_color={`rgba(${caring_rgb}, 1)`}
                label="Caring"
                filter="caring" />
              <FilterButton 
                icon={sentiment_icon}
                icon_selected={sentiment_icon_w}
                border_color={`rgba(${sentiment_rgb}, 1)`}
                bg_color={`rgba(${sentiment_rgb}, 0.85)`}
                label="Sentiment"
                filter="sentiment" 
              />
            </div>
            {data &&
              <div className="relative" style={{height: 300}} >
                <Line style={{height:800, position: "relative", zIndex:1}} id="home" data={data} options={options} />
                {toDate && selectedInsight && <div style={{
                  position: "absolute",
                  height: "80%",
                  width: 20,
                  backgroundColor: "rgba(171,171,171,0.5)",
                  top: 0,
                  left: GetPositionForMarker(),
                }}></div>}
              </div>
            }
           
        </div>
    )
}

export default LineChart;